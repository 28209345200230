<footer class="mt-5" [style.background-color]="getFooterColor()">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between flex-wrap">
                @if (leftPages.length > 0) {
                    <div class="d-flex gap-2">
                        <span
                            class="text-white p-2"
                            [innerHTML]="
                                'dynamische_frontend_texte.footer.html_content.copyright' | translate | safeStyle
                            "
                        ></span>
                        @for (page of leftPages; track page) {
                            <a
                                class="p-2"
                                [ngClass]="page.alignment"
                                [attr.aria-label]="page.alternative_text"
                                [routerLink]="[page.path_name.replace('/', '')]"
                                *otbShowInEnv="['hmdj', 'hzd-internal']"
                                >{{ page.name }}</a
                            >
                        }
                    </div>
                }

                <div class="d-flex gap-2">
                    <a
                        class="p-2 right"
                        [attr.aria-label]="
                            generateAriaLabel(
                                'dynamische_frontend_texte.footer.html_content.datenschutz',
                                'dynamische_frontend_texte.barrierefreiheit.attribute_text.externe_url_aria_label'
                            )
                        "
                        target="_blank"
                        [attr.href]="'dynamische_frontend_texte.footer.attribute_text.datenschutz_link' | translate"
                        [innerHTML]="
                            'dynamische_frontend_texte.footer.html_content.datenschutz' | translate | safeStyle
                        "
                    ></a>
                    <a
                        class="p-2 right"
                        [attr.aria-label]="
                            generateAriaLabel(
                                'dynamische_frontend_texte.footer.html_content.impressum',
                                'dynamische_frontend_texte.barrierefreiheit.attribute_text.externe_url_aria_label'
                            )
                        "
                        target="_blank"
                        [attr.href]="'dynamische_frontend_texte.footer.attribute_text.impressum_link' | translate"
                        [innerHTML]="'dynamische_frontend_texte.footer.html_content.impressum' | translate | safeStyle"
                    ></a>
                    @if (rightPages.length > 0) {
                        @for (page of rightPages; track page) {
                            <a
                                class="p-2"
                                [ngClass]="page.alignment"
                                [attr.aria-label]="page.alternative_text"
                                [routerLink]="[page.path_name.replace('/', '')]"
                                *otbShowInEnv="['hmdj', 'hzd-internal']"
                                >{{ page.name }}</a
                            >
                        }
                    }
                </div>
            </div>
        </div>
    </div>
</footer>
